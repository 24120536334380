body {
  /* background: #0e101c; */
  font-family: poppins-semibold,poppins,sans-serif;
  background-color: #0e0f1c;
  color: white;
}
.modal {
  color: #0e0f1c;
}
div.modal-body>p {
  text-decoration: none;
  color: #0e0f1c;
}

div.modal-body>p::before {
  display: inline;
  content: "";
}

.Header {
  /* background-color: white; */
  margin-bottom: 30px;
}

.subheader {
  color: lightgray;
  font-style: italic;
}
.subtitle>a {
  color: aqua;
  font-weight: bold;
  text-decoration: none;
}
.emphasize {
  color: wheat;
  font-weight: bold;
  font-style: italic;
}
.container,
form {
  max-width: 500px;
  margin: 0 auto;
  /* background: white; */
}

h1 {
  font-weight: bold;
  color: #B5E8B5;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
  /* font-family: poppins-semibold,poppins,sans-serif; */
}

h1>span {
  color: white !important;
}

p {
  color: #bf1650;
}

p::before {
  display: inline;
  content: "⚠ ";
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

a.btn {
  color: white !important;
  text-decoration: none;
}

btn, CSVLink,
button[type="submit"],
input[type="submit"] {
  background: #B5E8B5;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  /* letter-spacing: 10px; */
  width: 100%;
}

button[type="clear"] {
  width: 100%;
  background: red;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  /* letter-spacing: 10px; */
}


button[type="submit"]:hover,
input[type="submit"]:hover {
  background: #B5E8B5;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}



.App {
  max-width: 600px;
  margin: 0 auto;
}
input[type="button"]:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

button[type="button"] {
  display: block;
  appearance: none;
  /* background: #333; */
  /* color: white; */
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}
button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

hr {
  margin-top: 30px;
}

h2, h3 {
  color: white;
  font-weight: 200;
}


pre {
  color: white;
  white-space: pre;
}

.steps {
  color: white;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.steps a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 2;
}

.steps li {
  /* background: black; */
  padding: 3px 15px;
  border-right: 1px solid #333;
}

.steps li:last-child {
  border: none;
}

.active {
  border-bottom: 2px solid #B5E8B5 !important;
}

table {
  color: white;
}
